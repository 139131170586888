export class OpenCharge {

    chargeType: string;
    currency: string;
    documentDate: Date;
    grossAmount: string;
    overpaymentDate: Date;
    paymentDue: Date;
    recoveryAmount: string;
    referenceNumber: number;

    constructor(chargeType: string, currency: string, documentDate: Date, grossAmount: string, overpaymentDate: Date,
        paymentDue: Date, recoveryAmount: string, referenceNumber: number) {
        this.chargeType = chargeType
        this.currency = currency
        this.documentDate = documentDate
        this.grossAmount = grossAmount
        this.overpaymentDate = overpaymentDate
        this.paymentDue = paymentDue
        this.recoveryAmount = recoveryAmount
        this.referenceNumber = referenceNumber

    }

}


// chargeType: "DFW-DAY D1 FIRST"
// currency: "USD"
// documentDate: 1470614400000
// grossAmount: "26.1100 "
// overpaymentDate: null
// paymentDue: 1473206400000
// recoveryAmount: null
// referenceNumber: 9902159153