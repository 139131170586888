<div>
    <h1 class="title">Account activity</h1>
</div>
<!-- <div class="current_balance">
    <h4 class="subtitle">Current balance:</h4>
    <h4 class="balance">{{currentBalance}}</h4>
</div> -->
<h4 id="currentBalance" class="current_balance"> <span class="subtitle">Current balance: </span><span
        class="balance">{{currentBalance}}</span></h4>

<div class="portal_select_pay" *ngIf="isNested != true" id="buttonGroup">
    <!-- <input type="button" name="check" value="Pay by check" id="payCheckBtn" class="btn" (click)="openPopup('check')" /> -->
    <input type="button" name="paypal" value="Pay with Card via PayPal" id="payPalButton" class="btn"
        (click)="openPopup('paypal')" />
    <!-- <input disabled type="button" name="credit" value="Pay with credit card (coming soon)" id="payCreditBtn"
        class="btn" /> -->
</div>

<!-- Charge Type, Reference Number, Overpayment Date, Overpayment Recovery Amount, Gross Amount, Document Date, Due Date -->
<div class="table_container">
    <table class="aavp_table" style="overflow-y: hidden; overflow-x: auto;">
        <tr class="aavp_table_header">
            <th>Charge Type</th>
            <th>Reference Number</th>
            <th>Paycheck Reference Date</th>
            <th *ngIf="hasRecoveryAmount">Overpayment Recovery Amount</th>
            <th>{{amountHeader}}</th>
            <th>Activity Date</th>
            <th>Due Date</th>
        </tr>
        <tr class="aavp_table_row"  *ngFor="let charge of charges">
            <td>{{charge.chargeType}}</td>
            <td>{{(charge.referenceNumber) || "N/A"}}</td>
            <td>{{(charge.overpaymentDate | date: 'MM/dd/yyyy':'+0000') || "N/A"}}</td>
            <ng-container *ngIf="hasRecoveryAmount">
                <td>{{ isOverpaymentValid(charge.overpaymentDate) ? charge.recoveryAmount : 'N/A' }}</td>
            </ng-container>
            <td>{{charge.grossAmount}}</td>
            <td>{{(charge.documentDate | date: 'MM/dd/yyyy':'+0000') || "N/A"}}</td>
            <td>{{(charge.paymentDue | date: 'MM/dd/yyyy':'+0000') || "N/A"}}</td>
        </tr>
    </table>
</div>



<!-- 
<div class="aavp_table" style="overflow-y: hidden; overflow-x: auto;">
    -- REMOVED hidden-xs from below 
    <div class="aavp_table_header   ">
        <div [ngClass]="{'charge_item': true, 'six_columns': !hasRecoveryAmount, 'seven_columns': hasRecoveryAmount}">
            <div>Charge Type</div>
            <div>Reference Number</div>
            <div>Paycheck Reference Date</div>
            <div *ngIf="hasRecoveryAmount">Overpayment Recovery Amount</div>
            <div class="left">{{amountHeader}}</div>
            <div class="left">Activity Date</div>
            <div class="left">Due Date</div>
        </div>
    </div>
    <div class="aavp_charge_container">
        <div class="charge_item_container" *ngFor="let charge of charges">
            <div [ngClass]="{'charge_item': charge.chargeType != 'Payment',
                                                             'pay_item': charge.chargeType == 'Payment',
                                                             'six_columns': !hasRecoveryAmount,
                                                             'seven_columns': hasRecoveryAmount}">
                <div>{{charge.chargeType}}</div>
                <div>{{(charge.referenceNumber) || "N/A"}}</div>
                <div>{{(charge.overpaymentDate | date: 'MM/dd/yyyy':'+0000') || "N/A"}}</div>
                <div *ngIf="hasRecoveryAmount">{{charge.recoveryAmount}}</div>
                <div>{{charge.grossAmount}}</div>
                <div>{{(charge.documentDate | date: 'MM/dd/yyyy':'+0000') || "N/A"}}</div>
                <div>{{(charge.paymentDue | date: 'MM/dd/yyyy':'+0000') || "N/A"}}</div>
            </div>
        </div>
    </div>
</div> -->